<template>
  <div class="course_list_container">
    <div class="tabs">
      <div :class="{actived:category == 0}" @click="category = 0">幼儿阶段</div>
      <div :class="{actived:category == 1}" @click="category = 1">少儿阶段</div>
      <div :class="{actived:category == 2}" @click="category = 2">初高中阶段</div>
    </div>
    <div class="items">
      <div class="item" v-for="(item,key) in items" :key="key">
        <img class="cover" :src="item.pic" />
        <div class="title">{{item.name}}</div>
        <div class="info">
          <div class="desc">学制课时：<span style="color:#FF6F1B;">{{item.class_period}}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      category: 0,
      originItems: [],
    };
  },
  computed: {
    items() {
      console.log(this.originItems);
      return this.originItems.filter((item) => {
        return item.category == this.category;
      });
    },
  },
  created() {
    this.$api("/official/page/textbook").then(({ data }) => {
      this.originItems = data.textbook;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.course_list_container {
  .tabs {
    padding-top: 77px;
    display: flex;
    // align-items: center;
    justify-content: center;

    > * {
      margin: 0 29px;

      border: 1px solid rgba($color: #ff6e00, $alpha: 0.5);
      border-radius: 58px;

      transition: 0.5s;

      &.actived {
        background: #ff6e00;
        color: #ffffff;
      }

      padding: 20px 82px;
      color: #ff6e00;
      font-size: 50px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      // line-height: 73px;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 450px;

    .item {
      padding-top: 77px;

      .cover {
        width: 1341px;
        height: 989px;
        border-radius: 58px;
      }
      .title {
        margin: 0 46px;
        padding-top: 26px;

        font-size: 65px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 92px;
        text-shadow: 0px 4px 54px rgba(0, 0, 0, 0.11);
      }
      .info {
        padding-top: 38px;
        margin: 0 46px;

        font-size: 58px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 81px;
        text-shadow: 0px 8px 61px rgba(0, 0, 0, 0.11);

        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>